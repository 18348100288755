/* palanquin-100normal - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Palanquin Thin '),
    local('Palanquin-Thin'),
    url('./files/palanquin-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/palanquin-latin-100.woff') format('woff'); /* Modern Browsers */
}

/* palanquin-200normal - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Palanquin Extra Light '),
    local('Palanquin-Extra Light'),
    url('./files/palanquin-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/palanquin-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* palanquin-300normal - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Palanquin Light '),
    local('Palanquin-Light'),
    url('./files/palanquin-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/palanquin-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* palanquin-400normal - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Palanquin Regular '),
    local('Palanquin-Regular'),
    url('./files/palanquin-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/palanquin-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* palanquin-500normal - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Palanquin Medium '),
    local('Palanquin-Medium'),
    url('./files/palanquin-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/palanquin-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* palanquin-600normal - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Palanquin SemiBold '),
    local('Palanquin-SemiBold'),
    url('./files/palanquin-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/palanquin-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* palanquin-700normal - latin */
@font-face {
  font-family: 'Palanquin';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Palanquin Bold '),
    local('Palanquin-Bold'),
    url('./files/palanquin-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/palanquin-latin-700.woff') format('woff'); /* Modern Browsers */
}

